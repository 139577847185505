var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('core-page-title'),_c('v-container',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-plus')}}),_vm._v(" Adicionar ")],1),_c('v-btn',{attrs:{"color":"info","outlined":""},on:{"click":function($event){_vm.showDialogFilters = true}}},[_c('v-icon',{staticClass:"mr-2",domProps:{"textContent":_vm._s('mdi-filter')}}),_vm._v(" Filtros ")],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"1"}},[_c('v-data-table',{attrs:{"disable-filtering":"","disable-sort":"","server-items-length":_vm.count,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.tasks,"options":_vm.pagination,"footer-props":{
              'items-per-page-options': [10, 20, 50],
            }},on:{"update:options":[function($event){_vm.pagination=$event},_vm.loadTasks]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":_vm.calculateStatusColor(item)}},[_c('small',[_c('b',[_vm._v(_vm._s(item.id))])])])]}},{key:"item.deliveryDeadlineDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.deliveryDeadlineDate))+" ")]}},{key:"item.completedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.completedAt))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.openDialogView(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Visualizar ")])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-eye')}})],1)],1),_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Editar ")])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-pencil')}})],1)],1),_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.clickDeleteItem(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Deletar ")])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)],1),_c('dialog-customer-task',{attrs:{"task-id":_vm.editId},on:{"save":_vm.loadTasks},model:{value:(_vm.showDialogCustomerTask),callback:function ($$v) {_vm.showDialogCustomerTask=$$v},expression:"showDialogCustomerTask"}}),_c('dialog-customer-task-view',{attrs:{"task-id":_vm.viewId},on:{"completed":_vm.loadTasks},model:{value:(_vm.showDialogView),callback:function ($$v) {_vm.showDialogView=$$v},expression:"showDialogView"}}),_c('dialog-delete',{attrs:{"show":_vm.showDialogDelete,"loading":_vm.loadingDelete},on:{"confirm":_vm.deleteItem,"cancel":function($event){_vm.showDialogDelete = false}}}),_c('dialog-customer-task-filters',{on:{"filter":function (f) { return _vm.loadTasks(null, f); }},model:{value:(_vm.showDialogFilters),callback:function ($$v) {_vm.showDialogFilters=$$v},expression:"showDialogFilters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }