<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn color="success" class="mr-2" dark @click="openDialog()">
            <v-icon left v-text="'mdi-plus'" />
            Adicionar
          </v-btn>
          <v-btn color="info" outlined @click="showDialogFilters = true">
            <v-icon v-text="'mdi-filter'" class="mr-2" />
            Filtros
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="tasks"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50],
              }"
              @update:options="loadTasks"
            >
              <template v-slot:[`item.id`]="{ item }">
                <v-chip small dark :color="calculateStatusColor(item)">
                  <small><b>{{ item.id }}</b></small>
                </v-chip>
              </template>

              <template
                v-slot:[`item.deliveryDeadlineDate`]="{ item }"
                class="text-right"
              >
                {{ item.deliveryDeadlineDate | date }}
              </template>

              <template
                v-slot:[`item.completedAt`]="{ item }"
                class="text-right"
              >
                {{ item.completedAt | dateTime }}
              </template>

              <template v-slot:[`item.actions`]="{ item }" class="text-right">
                <v-menu bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link dense @click="openDialogView(item)">
                      <v-list-item-content>
                        <v-list-item-title>
                          Visualizar
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon v-text="'mdi-eye'" small />
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item link dense @click="openDialog(item)">
                      <v-list-item-content>
                        <v-list-item-title>
                          Editar
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon v-text="'mdi-pencil'" small />
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item link dense @click="clickDeleteItem(item)">
                      <v-list-item-content>
                        <v-list-item-title>
                          Deletar
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small>mdi-delete</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-customer-task
      v-model="showDialogCustomerTask"
      :task-id="editId"
      @save="loadTasks"
    />

    <dialog-customer-task-view
      v-model="showDialogView"
      :task-id="viewId"
      @completed="loadTasks"
    />

    <dialog-delete
      :show="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
      @cancel="showDialogDelete = false"
    />

    <dialog-customer-task-filters
      v-model="showDialogFilters"
      @filter="f => loadTasks(null, f)"
    />
  </div>
</template>

<script>
import customerTasksApi from '@/api/customer-tasks'
import dayjs from 'dayjs'
import _ from 'lodash'

export default {
  components: {
    DialogDelete: () => import('@/components/dialog/DialogDelete'),
    DialogCustomerTask: () =>
      import('@/components/dialog/customer-tasks/DialogCustomerTask'),
    DialogCustomerTaskView: () =>
      import('@/components/dialog/customer-tasks/DialogCustomerTaskView'),
    DialogCustomerTaskFilters: () =>
      import('@/components/dialog/customer-tasks/DialogCustomerTaskFilters'),
  },

  data() {
    return {
      loading: false,
      loadingDelete: false,
      showDialogCustomerTask: false,
      showDialogDelete: false,
      showDialogView: false,
      showDialogFilters: false,
      count: 0,
      deleteId: null,
      editId: null,
      viewId: null,
      headers: [
        { text: '#', value: 'id', align: 'center' },
        { text: 'Título', value: 'title' },
        { text: 'Cliente', value: 'customer.companyName' },
        { text: 'Data de vencimento', value: 'deliveryDeadlineDate' },
        { text: 'Finalizada em', value: 'completedAt' },
        { text: '', value: 'actions', align: 'right' },
      ],
      tasks: [],
      pagination: {
        itemsPerPage: 20,
      },
    }
  },

  methods: {
    async loadTasks(table = null, filters = {}) {
      try {
        this.loading = true

        const response = await customerTasksApi.list({
          limit: this.pagination.itemsPerPage,
          offset:
            this.pagination.page * this.pagination.itemsPerPage -
            this.pagination.itemsPerPage,
          completed: 0,
          ...(_.pickBy(filters, v => !_.isNull(v)) || {}),
        })

        this.tasks = response.data.tasks
        this.count = response.data.count
      } catch (e) {
        console.error(e)
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    openDialog(task = null) {
      this.editId = task ? task.id : null
      this.showDialogCustomerTask = true
    },

    openDialogView(task = null) {
      this.viewId = task ? task.id : null
      this.showDialogView = true
    },

    async clickDeleteItem(task) {
      this.deleteId = task.id
      this.showDialogDelete = true
    },

    calculateStatusColor(task) {
      const COLORS = {
        1: 'success',
        2: 'warning',
        3: 'error',
        4: 'info',
      }

      return COLORS[task.status]
    },

    async deleteItem() {
      try {
        this.loadingDelete = true

        await customerTasksApi.delete(this.deleteId)

        this.showDialogDelete = false
        this.loadTasks()

        this.$snackbar.show({
          color: 'success',
          message: this.$messages._('delete_success'),
        })
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingDelete = false
        this.dialogDelete = false
      }
    },
  },
}
</script>
